@import '../../styles/common';

div.banner-block {
  @include mobile {
    display: none;
  }

  width: 100%;
  height: 100px;

  background: $white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &.banner-bottom {
    position: fixed;
    bottom: 0;
    z-index: 4;

    border: 1px solid $black;
  }

  .banner-content {
    max-width: $site-max-size;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .banner-item {
    flex: 1 0 33.33%;

    &.banner-with-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100px;
    }
  }

  .banner-with-detail {
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    right: 1em;
    z-index: 999999;
    .search-box {
      display: flex;
      justify-content: flex-end;
      background-color: $white;
      /* for demonstration */
      padding: 10px;
      position: relative;
      z-index: 999999;
      .search-box-result {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        background: white;
        z-index: 999999;
        transform: translateY(100%);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 0.1vw 0.4vw;
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        overflow: hidden;

        &.hide {
          position: fixed;
          display: none !important;
          & > .search-box-result-item {
            position: fixed;
            display: none !important;
          }
        }

        & > .search-box-result-item {
          display: flex;
          align-items: center;
          padding: 1em;
          gap: 1em;
          font-size: 0.7em;
          font-weight: normal;

          &:hover {
            background: #f0f7fa;
          }

          img {
            width: 4em;
            height: 4em;
            object-fit: contain;
          }

          & > .search-box-result-item-name {
            padding-top: 0.05em;
            width: 10em;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-transform: none;
            height: 100%;
          }
          & > .search-box-result-item-price {
            flex: 1;
            height: 100%;
            text-align: right;
          }
        }
        & > .search-box-result-all {
          color: #23a1d1;
          font-size: 1em;
          font-weight: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.5em;
          background: #f0f7fa;
        }
      }
    }

    .search-box input {
      border: 0;
      padding: 0;
      width: 0;
      height: 15px;
      padding-right: 8px;
      font-size: 14px;
      transition: padding 0.6s ease-in-out, width 0.6s ease-in-out;
      background-color: transparent;
      color: var(--data-main-color-general);

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--data-main-color-general);
        opacity: 1;
        /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--data-main-color-general);
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--data-main-color-general);
      }
    }

    .search-box.open-seach-box {
      background-color: #f2f6f6;

      > input {
        display: inline-block;
        width: 16em;
        outline: none;
        color: var(--data-main-color-general);
        padding: 8px;
      }
    }

    .btn-login {
      width: 1.8rem;
      // height: 1.8rem;
      overflow: hidden;
      padding: 0;
      margin: 0 8px;
      text-align: center;

      img {
        width: 100%;
        // height: auto;
        // margin-right: 0.6px;
      }
    }
  }
}

.banner-mb-with-shoppingcart {
  background: $primary_color;

  ul {
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em 0;

    li {
      padding: 0 2em;
      display: flex;
      justify-items: center;
      align-items: center;
      text-transform: uppercase;

      a {
        text-transform: uppercase;
        font-size: convert-rem(12);
        letter-spacing: 0.1em;
        display: flex;
        font-size: 0.9rem;
        align-items: center;
      }

      img {
        margin-right: 8px;
      }
    }
  }
}

.gg-search {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 100%;
  margin-left: -4px;
  margin-top: -4px;
  color: $main_black;
}

.gg-search::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  height: 8px;
  background: currentColor;
  transform: rotate(-45deg);
  top: 10px;
  left: 12px;
}

.no-product-found-message {
  font-size: 10px;
  text-align: center;
  padding: 5px;
}

.kids-menu-item {
  background: linear-gradient(
    -45deg,
    #82368b,
    #209cd7,
    #2072b7,
    #92c03e,
    #fddc00,
    #f5b031,
    #de2227
  );
  background: -webkit-linear-gradient(
    -45deg,
    #82368b,
    #209cd7,
    #2072b7,
    #92c03e,
    #fddc00,
    #f5b031,
    #de2227
  );
  background-size: 300%;
  font-weight: $font-weight-light;
  font-size: inherit;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animated_text 5s ease-in-out infinite;
  -moz-animation: animated_text 5s ease-in-out infinite;
  -webkit-animation: animated_text 5s ease-in-out infinite;
  cursor: pointer;
  &:hover {
    font-weight: $font-weight-semi-bold;
  }
}

@keyframes animated_text {
  0% {
    background-position: 0px 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0px 50%;
  }
}

.beauty-menu-item {
  font-weight: $font-weight-light;
  font-size: inherit;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--c, $primary_color);
  &:hover {
    font-weight: $font-weight-semi-bold;
  }
}
